import React from "react";
import { makeCardGridItems, LeadDetails } from "@leadId/index";
import { createLeadView, ViewComponent } from "@leadId/view";
import {
    PersonalInfo,
    LeadInfo,
    ContactInfo,
    MarketingInfo,
} from "@leadId/data/attributes";
import { useLeadView } from "@api/leads";
import { LeadContent } from "./_lib";

const BasicCards = makeCardGridItems<any, LeadDetails>([
    PersonalInfo,
    ContactInfo,
    LeadInfo,
    MarketingInfo,
]);

const Basic: ViewComponent = (props) => {
    const { lead, loading, error } = useLeadView(props.leadId, "");

    return (
        <LeadContent
            cards={BasicCards}
            lead={lead}
            loading={loading}
        />
    );
};

export default createLeadView(Basic, {});